import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PropTypes from "prop-types";
import SEO from "../components/seo";
import BibleBookStyles from "./article.module.css";


export default function BibleBook({ data }) {
  const title = data.markdownRemark.frontmatter.title;
  const html = data.markdownRemark.html;
  return (
    <Layout>
      <SEO
        keywords={[
          `${title}介紹`,
          `${title}簡介`,
          `${title}背景`,
          `${title}作者`,
          `${title}寫作目的`,
          `${title}歷史`,
        ]}
        title={`${title}介紹`}
        description={`介紹${title}背景、作者、歷史和寫作目的，把${title}劃分為不同部分，讓你可以更好了解${title}的事序和涵義。`}
      />
      <h1 className="text-center font-bold text-2xl m-2">{title}</h1>
      {/* <div id={BibleBookStyles.bibleBookBody}>{RichText.render(body)}</div> */}
      <div
          id={BibleBookStyles.bibleBookBody}
          dangerouslySetInnerHTML={{ __html: html }}
        />
    </Layout>
  );
}

BibleBook.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string
      })
    }),
  }),
};

export const query = graphql`
  query($ID: String) {
    markdownRemark(id: {eq: $ID}) {
      html
      frontmatter {
        title
      }
    }
  }
`;
